import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography/Typography";
import TextField from "@mui/material/TextField/TextField";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List/List";
import {
  Box,
  Checkbox,
  Divider,
  IconButton,
  ListItem,
  useMediaQuery,
} from "@mui/material";

import Button from "@mui/material/Button";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { PersonSource, PersonTypeId } from "cwpersontypes";
import { useContext, useEffect, useRef, useState } from "react";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { drawer_close_button, drawer_open_button } from "./FilterDrawerStyles";
import PeopleContext from "../../contexts/PeopleContext";

type Props = {
  leadSources: PersonSource[];
};

const FilterDawer = ({ leadSources }: Props) => {
  const [openDawer, setOpenDrawer] = useState<boolean>(false);
  const { filters, setFilters } = useContext(PeopleContext);

  const [selectedLead, setSelectedLead] = useState<string | never[]>([]);
  const [selectedType, setSelectedType] = useState<string | never[]>([]);

  const isRequireEmail = useRef(false);
  const isRequirePhone = useRef(false);

  const isMobile = useMediaQuery("(max-width: 600px)");

  useEffect(() => {
    if (isMobile) {
      setOpenDrawer(false);
    } else {
      setOpenDrawer(true);
    }
  }, [setOpenDrawer, isMobile]);

  useEffect(() => {
    if (!filters.leadSourceIds) {
      setSelectedLead([]);
    }
    if (!filters.personTypeIds) {
      setSelectedType([]);
    }
  }, [filters]);

  const filterLocation = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const address = data.get("address") as string;
    const radius = data.get("radius") as string;
    if (!address || !radius) return;

    setFilters({
      ...filters,
      inputLocation: { address: address, radius: +radius },
    });
  };

  const handleRequire = (isChecked: boolean, type: string) => {
    switch (type) {
      case "email":
        isRequireEmail.current = isChecked;
        setFilters({
          ...filters,
          emailRequired: isChecked,
        });
        return;
      case "phone":
        isRequirePhone.current = isChecked;
        setFilters({
          ...filters,
          phoneRequired: isChecked,
        });
        return;
      default:
        return;
    }
  };

  const handleFilter = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const copyFilters = { ...filters };
    copyFilters.leadSourceIds = selectedLead;
    copyFilters.personTypeIds = selectedType;
    setFilters(copyFilters);
  };

  return (
    <>
      {isMobile && (
        <IconButton
          onClick={() => setOpenDrawer(!openDawer)}
          sx={drawer_open_button}
        >
          <TuneOutlinedIcon fontSize="large" />
        </IconButton>
      )}

      <Drawer
        sx={{
          "& .MuiDrawer-paper": {
            width: isMobile ? "100%" : 350,
            boxSizing: "border-box",
            position: isMobile ? "absolute" : "relative",
            overflow: "hidden",
          },
        }}
        variant="persistent"
        anchor="left"
        open={openDawer}
      >
        {isMobile && (
          <IconButton
            onClick={() => setOpenDrawer(!openDawer)}
            sx={drawer_close_button}
          >
            <ArrowBackIosNewIcon fontSize="large" />
          </IconButton>
        )}
        <List>
          <ListItem
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              boxSizing: "border-box",
            }}
          >
            <Typography variant="h5" width="100%">
              Filter
            </Typography>

            <FormControl
              sx={{
                width: "100%",
                padding: "0.5rem",
                boxSizing: "border-box",
              }}
              component="form"
              onSubmit={handleFilter}
            >
              <Box sx={{ display: "flex" }}>
                <FormControl sx={{ m: 1, width: "100%", margin: "0 0 1rem 0" }}>
                  <InputLabel id="demo-multiple-name-label">
                    Lead Source
                  </InputLabel>
                  <Select
                    multiple
                    value={selectedLead}
                    onChange={(event) => setSelectedLead(event.target.value)}
                    input={<OutlinedInput label="Lead Source" />}
                  >
                    {leadSources.map((source) => (
                      <MenuItem key={source.uuid} value={source.uuid}>
                        {source.sourceName?.toUpperCase()}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {filters.leadSourceIds?.length ? (
                  <IconButton
                    sx={{ width: "50px", margin: "0 0 1rem 0.5rem " }}
                    onClick={
                      () => setSelectedLead([])
                      // setFilters({ ...filters, leadSourceIds: [] })
                    }
                  >
                    <HighlightOffIcon />
                  </IconButton>
                ) : (
                  <></>
                )}
              </Box>

              <Box sx={{ display: "flex" }}>
                <FormControl sx={{ m: 1, width: "100%", margin: "0 0 1rem 0" }}>
                  <InputLabel>Person Type</InputLabel>
                  <Select
                    multiple
                    value={selectedType}
                    onChange={(event) => setSelectedType(event.target.value)}
                    input={<OutlinedInput label="Person Type" />}
                  >
                    {Object.entries(PersonTypeId).map(([typeName, typeId]) => (
                      <MenuItem key={typeId} value={typeId}>
                        {typeName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {filters.personTypeIds?.length ? (
                  <IconButton
                    sx={{ width: "50px", margin: "0 0 1rem 0.5rem " }}
                    onClick={
                      () => setSelectedType([])
                      // {setFilters({ ...filters, personTypeIds: [] })}
                    }
                  >
                    <HighlightOffIcon />
                  </IconButton>
                ) : (
                  <></>
                )}
              </Box>

              <Button variant="contained" type="submit" sx={{ width: "100%" }}>
                Apply Filter
              </Button>
            </FormControl>
          </ListItem>
          <Divider />

          <ListItem
            sx={{
              display: "flex",
              flexDirection: "column",
              fontSize: "1rem",
              boxSizing: "border-box",
            }}
          >
            <Typography variant="h5" width="100%">
              Field Required
            </Typography>
            <Box
              sx={{
                width: "100%",
                marginLeft: "0.5rem",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Checkbox
                  onChange={(e) => handleRequire(e.target.checked, "email")}
                />
                <div>Email Required</div>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Checkbox
                  onChange={(e) => handleRequire(e.target.checked, "phone")}
                />
                <div>Phone Required</div>
              </Box>
            </Box>
          </ListItem>

          <Divider />

          <ListItem
            sx={{ width: "100%", display: "flex", flexDirection: "column" }}
          >
            <Typography variant="h5" width="100%">
              Location
            </Typography>

            <FormControl
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                boxSizing: "border-box",
                padding: "0.5rem",
              }}
              component="form"
              onSubmit={filterLocation}
            >
              <TextField
                label="Address"
                variant="outlined"
                name="address"
                type="string"
                defaultValue={
                  filters.inputLocation ? filters.inputLocation.address : ""
                }
                sx={{ marginBottom: "1rem" }}
              />
              <TextField
                label="Radius (miles)"
                variant="outlined"
                name="radius"
                type="string"
                defaultValue={
                  filters.inputLocation ? filters.inputLocation.radius : ""
                }
                sx={{ marginBottom: "1rem" }}
              />

              <Button variant="contained" type="submit" sx={{ width: "100%" }}>
                Apply Location
              </Button>
            </FormControl>
          </ListItem>
        </List>
      </Drawer>
    </>
  );
};

export default FilterDawer;
