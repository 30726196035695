import { useDropzone } from "react-dropzone";
import classes from "./ImportData.module.scss";
import Papa from "papaparse";

interface DropZoneProps {
  setNextReady: React.Dispatch<React.SetStateAction<boolean>>;
  csvData: React.MutableRefObject<string[][] | undefined>;
  setInputSource: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const DropZone = ({ setNextReady, csvData, setInputSource }: DropZoneProps) => {
  const onDrop = (acceptedFiles: any) => {
    if (acceptedFiles[0] && acceptedFiles[0].size < 5242880) {
      setNextReady(true);
      setInputSource(acceptedFiles[0].path);
      Papa.parse(acceptedFiles[0], {
        complete: function (results: { data: string[][] }) {
          csvData.current = results.data;
        },
      });
    }
  };

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      onDrop,
      multiple: false,
      accept: { "text/csv": [".csv"] },
    });

  const renderText = () => {
    if (fileRejections[0]) {
      return <div>The file is not the right file type </div>;
    } else if (acceptedFiles[0]?.size > 5242880) {
      return (
        <div>{`File size is too big - ${acceptedFiles[0].size} bytes`}</div>
      );
    } else if (acceptedFiles[0]) {
      return (
        <div>{`${acceptedFiles[0].name} - ${acceptedFiles[0].size} bytes`}</div>
      );
    }
    return (
      <p>Drag & drop some files here, or click to select files (5MB max)</p>
    );
  };

  return (
    <div className={classes["dropzone_container"]}>
      <div {...getRootProps({ className: classes["dropzone"] })}>
        <input {...getInputProps()} />

        {renderText()}
      </div>
    </div>
  );
};

export default DropZone;
