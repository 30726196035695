import React, { ReactNode } from "react";
import { Box, Typography, Divider } from "@mui/material";

interface ModalContentProps {
  children: ReactNode;
  width?: string | number;
  title?: string | undefined;
}

const StandardModal = ({ children, width, title }: ModalContentProps) => {
  return (
    <Box
      sx={{
        position: "fixed",
        top: "15%",
        left: "50%",
        transform: "translateX(-50%)",
        width: width || 400,
        bgcolor: "background.paper",
        boxShadow: 24,
        borderRadius: "1rem",
        margin: 0,
        "@media (max-width: 650px)": {
          width: "90%",
          maxHeight: "90%",
          overflowY: "auto",
          top: "5%",
        },
      }}
    >
      {title && (
        <Box sx={{ p: 2 }}>
          <Typography variant="h5">{title}</Typography>
        </Box>
      )}
      <Divider />
      <Box sx={{ p: 2 }}>{children}</Box>
    </Box>
  );
};

export default StandardModal;
