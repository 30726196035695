import { useState } from "react";
import StandardModal from "../../../../reusableComponents/StandardModal";
import { UserData } from "../UserManagement";
import LoadingButton from "@mui/lab/LoadingButton";
import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
type Props = {
  user: UserData | undefined;
  closeModal: () => void;
  userRemoved: (user: UserData) => void;
};

const DeleteUser = ({ user, closeModal, userRemoved }: Props) => {
  const [archiveLoading, setArchiveLoading] = useState<boolean>(false);

  const archiveUser = async () => {
    console.log("going to archive user: ", user);
    setArchiveLoading(true);
    if (!user?.uuid) return;
    try {
      const response = await axios.delete("https://api.cwcontacts.com/users", {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
        data: { uuid: user.uuid },
      });
      console.log("response ", response);
      userRemoved(user);
      closeModal();
    } catch (error) {
      console.error("error creating user", error);
    }
  };

  return (
    <StandardModal title={`Archive User ${user?.firstName} ${user?.lastName}?`}>
      <LoadingButton
        onClick={archiveUser}
        color="error"
        variant="contained"
        loading={archiveLoading}
        loadingPosition="start"
        startIcon={<DeleteIcon />}
      >
        Yes
      </LoadingButton>
      <Button onClick={closeModal}>No</Button>
    </StandardModal>
  );
};

export default DeleteUser;
