import classes from "./MatchingFields.module.scss";

interface MatchingHeaderProps {
  inputSource: string | undefined;
  setInputSource: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const MatchingHeader = ({
  inputSource,
  setInputSource,
}: MatchingHeaderProps) => {
  return (
    <>
      <div className={classes["matching_row"]}>
        <div className={classes["db_column"]} style={{ fontWeight: "bold" }}>
          DB Fields
        </div>
        <div className={classes["db_column"]} style={{ fontWeight: "bold" }}>
          CSV Fields
        </div>
      </div>
      <div className={classes["matching_row"]}>
        <div className={classes["db_column"]}>Source</div>
        <input
          className={classes["source_input"]}
          defaultValue={inputSource}
          onChange={(e) => {
            setInputSource(e.target.value);
          }}
        ></input>
      </div>
    </>
  );
};

export default MatchingHeader;
