import axios from "axios";
import { useEffect, useState } from "react";
import classes from "./BotStatus.module.css";

interface BotStatusType {
  bot_name: string;
  last_update: string;
}

const BotStatus = () => {
  const [bots, setBots] = useState<BotStatusType[]>([]);
  useEffect(() => {
    const getStatus = async () => {
      const botData: { data: BotStatusType[] } = await axios.get(
        "https://api.cwcontacts.com/contacts/botStatus"
      );
      const sortedBot = botData.data.sort((a, b) => {
        const dateA = new Date(a.last_update).getTime();
        const dateB = new Date(b.last_update).getTime();
        return dateB - dateA;
      });
      setBots(sortedBot);
    };

    getStatus();
  }, []);

  const transformDate = (date: string) => {
    const inputDate = new Date(date);
    if (!inputDate) return;
    const formattedDate = inputDate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });
    return formattedDate;
  };

  const isNewUpdate = (date: string) => {
    const today: any = new Date();
    const botDate: any = new Date(date);
    const timeDiff = today - botDate;
    const differenceInDays = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    return differenceInDays < 3;
  };

  return (
    <div className={classes["bot_status"]}>
      <div className={classes["bot_list"]}>
        {bots.map((bot, index) => {
          return (
            <div key={index} className={classes["bot_card"]}>
              <div className={classes["bot_name"]}>{bot.bot_name}</div>
              <div
                className={classes["bot_update"]}
                style={
                  isNewUpdate(bot.last_update)
                    ? { color: "#09c709", fontWeight: "bold" }
                    : {}
                }
              >
                {transformDate(bot.last_update)}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BotStatus;
