import Divider from "@mui/material/Divider/Divider";
import List from "@mui/material/List/List";
import ListItem from "@mui/material/ListItem/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon/ListItemIcon";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import TableContainer from "@mui/material/TableContainer/TableContainer";
import { Person } from "cwpersontypes";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import Box from "@mui/material/Box/Box";

const MobileList = ({
  people,
  reformatPhoneNumber,
}: {
  people: Person[];
  reformatPhoneNumber: (phoneNumber: string) => void;
}) => (
  <TableContainer sx={{ maxHeight: "650px" }}>
    <List>
      {people.map((person: Person) => (
        <div key={person.uuid}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: 0,
            }}
          >
            <ListItem sx={{ padding: 0 }}>
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  fontWeight: "bold",
                }}
                primary={`${person.firstName} ${person.lastName}`}
              />
            </ListItem>
            <ListItem sx={{ padding: 0 }}>
              <ListItemIcon>
                <EmailIcon />
              </ListItemIcon>
              <ListItemText
                primary={`${
                  person.emailAddresses.length
                    ? person.emailAddresses[0].address
                    : ""
                }`}
              />
            </ListItem>
            <ListItem sx={{ padding: 0 }}>
              <ListItemIcon>
                <PhoneInTalkIcon />
              </ListItemIcon>
              <ListItemText
                primary={`${
                  person.phoneNumbers.length
                    ? reformatPhoneNumber(person.phoneNumbers[0].number)
                    : ""
                }`}
              />
            </ListItem>
          </Box>
          <Divider />
        </div>
      ))}
    </List>
  </TableContainer>
);

export default MobileList;
