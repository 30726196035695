import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import MobileList from "./MobileList";
import BrowserList from "./BrowserList";
import classes from "./ContactBody.module.scss";
import { Person } from "cwpersontypes";

interface ContactBodyProps {
  loading: boolean;
  isMobile: boolean;
  people: Person[];
  showColumns: number[];
}

const ContactBody = ({
  loading,
  isMobile,
  people,
  showColumns,
}: ContactBodyProps) => {
  const reformatPhoneNumber = (phoneNumber: string) => {
    const cleanedPhoneNumber = phoneNumber.replace(/\D/g, "");
    if (cleanedPhoneNumber.length !== 10) {
      return phoneNumber;
    }
    const areaCode = cleanedPhoneNumber.substring(0, 3);
    const firstPart = cleanedPhoneNumber.substring(3, 6);
    const secondPart = cleanedPhoneNumber.substring(6, 10);

    return `(${areaCode}) ${firstPart} ${secondPart}`;
  };

  return (
    <div className={classes["table_container"]}>
      {loading ? (
        <div className={classes["loading"]}>
          <CircularProgress size={80} />
        </div>
      ) : (
        <>
          {isMobile ? (
            <MobileList
              people={people}
              reformatPhoneNumber={reformatPhoneNumber}
            />
          ) : (
            <BrowserList
              people={people}
              columns={showColumns}
              reformatPhoneNumber={reformatPhoneNumber}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ContactBody;
