import Checkbox from "@mui/material/Checkbox/Checkbox";
import Table from "@mui/material/Table/Table";
import TableBody from "@mui/material/TableBody/TableBody";
import TableCell from "@mui/material/TableCell/TableCell";
import TableContainer from "@mui/material/TableContainer/TableContainer";
import TableHead from "@mui/material/TableHead/TableHead";
import TableRow from "@mui/material/TableRow/TableRow";
import { ActiveCity, Person, PersonData } from "cwpersontypes";
import {
  Facebook,
  Twitter,
  LinkedIn,
  Instagram,
  YouTube,
} from "@mui/icons-material";
import {
  Badge,
  Box,
  IconButton,
  List,
  ListItem,
  Modal,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import PeopleContext from "../../contexts/PeopleContext";
import { contactFields } from "../../dashBoard/settings/importData/importHelper";

import classes from "./PeopleContact.module.scss";

interface ModalPage {
  open: boolean;
  field: string;
  childNode: React.ReactNode;
}

const socialMediaIcon: { [key: string]: JSX.Element } = {
  facebook: <Facebook sx={{ color: "#3b5998" }} />,
  twitter: <Twitter sx={{ color: "#1DA1F2" }} />,
  linkedin: <LinkedIn sx={{ color: "#0A66C2" }} />,
  instagram: <Instagram sx={{ color: "#fbad50" }} />,
  youtube: <YouTube sx={{ color: "#c4302b" }} />,
};

const BrowserList = ({
  people,
  columns,
  reformatPhoneNumber,
}: {
  people: Person[];
  columns: number[];
  reformatPhoneNumber: (phoneNumber: string) => string;
}) => {
  const [modalPage, setModalPage] = useState<ModalPage>({
    open: false,
    field: "",
    childNode: null,
  });
  const { filters, setFilters } = useContext(PeopleContext);
  let orderBy: "first_name" | "last_name" =
    filters.orderBy?.field || "first_name";
  let direction: "desc" | "asc" | undefined =
    filters.orderBy?.direction.toLowerCase() || "asc";

  const handleSort = (field: "first_name" | "last_name") => {
    if (field) {
      orderBy = field;
    }
    direction = direction === "asc" ? "desc" : "asc";

    setFilters({
      ...filters,
      orderBy: { field: orderBy, direction: direction.toUpperCase() },
    });
  };

  return (
    <TableContainer>
      <Table
        padding={"none"}
        stickyHeader
        sx={{
          "& th": { fontWeight: "bold" },
          width: "100%",
          overflow: "auto",
        }}
      >
        <TableHead>
          <TableRow key={"table-header"}>
            <TableCell>
              <Checkbox />
            </TableCell>
            <TableCell
              key="first_name"
              className={classes["table_rows"]}
              sortDirection={orderBy === "first_name" ? direction : false}
            >
              <TableSortLabel
                active={orderBy === "first_name"}
                direction={orderBy === "first_name" ? direction : "asc"}
                onClick={() => handleSort("first_name")}
              >
                First Name
              </TableSortLabel>
            </TableCell>
            <TableCell
              key="last_name"
              className={classes["table_rows"]}
              sortDirection={orderBy === "last_name" ? direction : false}
            >
              <TableSortLabel
                active={orderBy === "last_name"}
                direction={orderBy === "last_name" ? direction : "asc"}
                onClick={() => handleSort("last_name")}
              >
                Last Name
              </TableSortLabel>
            </TableCell>

            {columns.map((column, index) => (
              <TableCell key={index} className={classes["table_rows"]}>
                {contactFields[column].label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {people.map((person: Person) =>
            getTableRow(person, columns, reformatPhoneNumber, setModalPage)
          )}
        </TableBody>
      </Table>
      <Modal
        open={modalPage.open}
        onClose={() =>
          setModalPage((prevState) => ({ ...prevState, open: false }))
        }
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            maxHeight: "80vh",
            overflowY: "auto",
          }}
        >
          {modalPage.childNode}
        </Box>
      </Modal>
    </TableContainer>
  );
};

interface PersonDatasProps {
  personDatas: PersonData[];
  field: string;
  index: number;
}

const PersonDatas = ({ personDatas, field, index }: PersonDatasProps) => {
  // console.log("personData", personDatas);
  if (personDatas?.length) {
    const fieldIndex = personDatas.findIndex(
      (d) => d.personFieldName === field
    );
    return fieldIndex >= 0 ? (
      <TableCell
        key={personDatas[fieldIndex].uuid}
        className={classes["table_rows"]}
        style={{
          maxWidth: "150px",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <Typography noWrap>{personDatas[fieldIndex].value}</Typography>
      </TableCell>
    ) : (
      <TableCell key={index}></TableCell>
    );
  } else {
    return <TableCell key={index}></TableCell>;
  }
};

const getTableRow = (
  person: Person,
  columns: number[],
  reformatPhoneNumber: (phoneNumber: string) => string,
  setModalPage: React.Dispatch<React.SetStateAction<ModalPage>>
) => {
  const handleOpenModal = (field: string, value: any) => {
    let childNode: JSX.Element;
    if (field === "Active Cities") {
      childNode = (
        <>
          <Typography component="h4" sx={{ fontWeight: " bold" }}>
            Active Cities:
          </Typography>
          <List>
            {value.map((city: ActiveCity) => (
              <ListItem>{`${city.cityName}, ${city.region}`}</ListItem>
            ))}
          </List>
        </>
      );
    }

    setModalPage((prevState) => ({
      ...prevState,
      open: true,
      field: field,
      childNode: childNode,
    }));
  };

  return (
    <TableRow key={person.uuid} sx={{ height: "29px" }}>
      <TableCell>
        <Checkbox />
      </TableCell>
      <TableCell key={"firstName"}>{person.firstName}</TableCell>
      <TableCell key={"lastName"}>{person.lastName}</TableCell>
      {columns.map((column, index) => {
        switch (contactFields[column].label) {
          case "Email":
            return (
              <TableCell key={person.uuid} className={classes["table_rows"]}>
                {person.emailAddresses?.length
                  ? person.emailAddresses[0].address
                  : ""}
              </TableCell>
            );
          case "Phone":
            return (
              <TableCell key={index} className={classes["table_rows"]}>
                {person.phoneNumbers?.length
                  ? reformatPhoneNumber(person.phoneNumbers[0].number)
                  : ""}
              </TableCell>
            );
          case "Source":
            return (
              <TableCell key={index} className={classes["table_rows"]}>
                {person.sources?.length ? person.sources[0].sourceName : ""}
              </TableCell>
            );
          case "Active Cities":
            return (
              <TableCell key={index} className={classes["table_rows"]}>
                {person.activeCities?.length && (
                  <IconButton
                    sx={{
                      borderRadius: "5px",
                      padding: "5px",
                      fontSize: "inherit",
                      color: "inherit",
                    }}
                    onClick={() =>
                      handleOpenModal("Active Cities", person.activeCities)
                    }
                  >
                    <Badge
                      badgeContent={person.activeCities.length - 1}
                      sx={{ paddingRight: "10px" }}
                      color="primary"
                    >
                      {`${person.activeCities[0].cityName}, ${person.activeCities[0].region} `}
                    </Badge>
                  </IconButton>
                )}
              </TableCell>
            );
          case "Photos":
            return (
              <TableCell key={index} className={classes["table_rows"]}>
                {person.photos?.length ? (
                  <img
                    src={person.photos[0].href}
                    alt="userphoto"
                    width="20px"
                  />
                ) : (
                  ""
                )}
              </TableCell>
            );

          case "Social Media":
            return (
              <TableCell key={index} className={classes["table_rows"]}>
                {person.socialMediaLinks?.length ? (
                  <>
                    {person.socialMediaLinks.map((socialMedia) => {
                      return (
                        <IconButton
                          key={socialMedia.href}
                          sx={{ borderRadius: "5px", padding: "2px" }}
                          onClick={() =>
                            window.open(socialMedia.href, "_blank")
                          }
                        >
                          {socialMedia.socialMediaType &&
                            socialMediaIcon[socialMedia.socialMediaType]}
                        </IconButton>
                      );
                    })}
                  </>
                ) : (
                  <></>
                )}
              </TableCell>
            );
          case "Person Type":
            return (
              <TableCell key={index} className={classes["table_rows"]}>
                {person.personType}
              </TableCell>
            );
          default:
            return (
              <PersonDatas
                personDatas={person.personDatas}
                field={contactFields[column].field}
                index={index}
              />
            );
        }
      })}
    </TableRow>
  );
};

export default BrowserList;
