import { Route, Routes, useNavigate } from "react-router-dom";
import UserManagement from "./userManagement/UserManagement";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import {
  Box,
  CSSObject,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Theme,
  Toolbar,
  styled,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import RuleIcon from "@mui/icons-material/Rule";
import { ReactElement } from "react";
import { useWindowSize } from "@uidotdev/usehooks";
import { useUserContext } from "../../contexts/UserContext";
import Validation from "./validation/Validation";
import ImportData from "./importData/ImportData";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

type SettingItem = {
  name: string;
  path: string;
  icon: ReactElement;
  roles: string[];
};

const items: SettingItem[] = [
  {
    name: "Import Data",
    path: "import",
    icon: <UploadFileIcon />,
    roles: [
      "44820fb8-c928-499e-b5d0-57c5444457ce",
      "85341695-e4d4-4abb-aa42-242131152b3b",
    ],
  },
  {
    name: "Validation",
    path: "validation",
    icon: <RuleIcon />,
    roles: [
      "44820fb8-c928-499e-b5d0-57c5444457ce",
      "85341695-e4d4-4abb-aa42-242131152b3b",
    ],
  },
  {
    name: "Manage Users",
    path: "users",
    icon: <AccountCircleIcon />,
    roles: ["44820fb8-c928-499e-b5d0-57c5444457ce"],
  },
];

const Settings = () => {
  const navigate = useNavigate();
  const size = useWindowSize();
  const expanded = size.width ? size.width > 700 : false;
  const { user } = useUserContext();

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <Drawer variant="permanent" open={expanded}>
        <Toolbar />
        <List>
          {items
            .filter((i) => i.roles.includes(user?.roleId || ""))
            .map((item: SettingItem) => (
              <ListItemButton
                key={item.name}
                onClick={() => navigate(`${item.path}`)}
                selected={window.location.pathname.includes(item.path)}
                sx={{
                  minHeight: 48,
                  justifyContent: expanded ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: expanded ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText sx={{ opacity: expanded ? 1 : 0 }}>
                  {item.name}
                </ListItemText>
              </ListItemButton>
            ))}
        </List>
      </Drawer>
      <Box
        display="flex"
        component="main"
        sx={{ flexGrow: 1, p: 3, display: "flex", justifyContent: "center" }}
      >
        {user?.roleId === "44820fb8-c928-499e-b5d0-57c5444457ce" ? (
          <Routes>
            <Route path="/users" element={<UserManagement />} />
            <Route path="/validation" element={<Validation />} />
            <Route path="/import" element={<ImportData />} />
          </Routes>
        ) : (
          <></>
        )}
      </Box>
    </div>
  );
};

export default Settings;
