import axios from "axios";
import { PersonSource } from "cwpersontypes";

export const getSources = async (): Promise<PersonSource[]> => {
  try {
    const sources: { data: PersonSource[] } = await axios.get(
      "https://api.cwcontacts.com/contacts/source"
    );

    return sources.data;
  } catch (error: any) {
    console.error("error get sources", error);
    throw error;
  }
};

export const createSource = async (
  sourceName: string
): Promise<PersonSource> => {
  try {
    const newSource: { data: PersonSource } = await axios.post(
      "https://api.cwcontacts.com/contacts/source",
      { name: sourceName }
    );

    return newSource.data;
  } catch (error) {
    console.error("error create source", error);
    throw error;
  }
};
