import { UserData } from "../UserManagement";
import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import StandardModal from "../../../../reusableComponents/StandardModal";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import AddIcon from "@mui/icons-material/Add";
import {
  Stack,
  TextField,
  InputLabel,
  FormControl,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
type Props = {
  user: UserData | undefined;
  closeModal: () => void;
  userPatched: (user: UserData) => void;
};

const EditUser = ({ user, closeModal, userPatched }: Props) => {
  console.log("editing user", user);

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<string>();
  const patchUser = async (data: UserData) => {
    if (data.roleId === "85341695-e4d4-4abb-aa42-242131152b3b")
      data.roleName = "user";
    else data.roleName = "admin";
    setSubmitting(true);
    try {
      await axios.patch("https://api.cwcontacts.com/users", data, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      });
      userPatched(data);
      closeModal();
    } catch (error: any) {
      setSubmitError(error?.response?.data);
      console.error("error updating user", error);
      setSubmitting(false);
    }
  };

  const {
    handleSubmit,
    control,
    formState: { isValid, errors },
  } = useForm<UserData>({ mode: "onChange", defaultValues: user });

  return (
    <StandardModal title={`Update ${user?.firstName} ${user?.lastName}`}>
      <Stack spacing={2}>
        <Controller
          control={control}
          name="firstName"
          rules={{
            required: true,
            minLength: {
              value: 3,
              message: "Must be at least 3 characters",
            },
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              helperText={error ? error.message : null}
              size="small"
              error={!!error}
              onChange={onChange}
              value={value}
              fullWidth
              label={"First Name"}
              variant="outlined"
            />
          )}
        />
        <Controller
          control={control}
          name="lastName"
          rules={{
            required: true,
            minLength: {
              value: 3,
              message: "Must be at least 3 characters",
            },
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              helperText={error ? error.message : null}
              size="small"
              error={!!error}
              onChange={onChange}
              value={value}
              fullWidth
              label={"Last Name"}
              variant="outlined"
            />
          )}
        />
        <Controller
          control={control}
          name="email"
          rules={{
            required: true,
            pattern: {
              value:
                /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
              message: "Not a valid email address",
            },
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              helperText={error ? error.message : null}
              size="small"
              error={!!error}
              onChange={onChange}
              value={value}
              fullWidth
              label={"Email"}
              variant="outlined"
            />
          )}
        />

        <Controller
          control={control}
          name="roleId"
          rules={{
            required: true,
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormControl fullWidth>
              <InputLabel size="small">Role</InputLabel>

              <Select
                size="small"
                label="Role"
                value={value}
                onChange={onChange}
              >
                <MenuItem value="85341695-e4d4-4abb-aa42-242131152b3b">
                  Standard User
                </MenuItem>
                <MenuItem value="44820fb8-c928-499e-b5d0-57c5444457ce">
                  Admin
                </MenuItem>
              </Select>
            </FormControl>
          )}
        />

        <LoadingButton
          onClick={handleSubmit(patchUser)}
          startIcon={<AddIcon />}
          variant="contained"
          disabled={!isValid}
          loadingPosition="start"
          loading={submitting}
          sx={{ width: "fit-content" }}
        >
          Update User
        </LoadingButton>
      </Stack>
      <Dialog
        open={submitError !== undefined}
        onClose={() => setSubmitError(undefined)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Error Creating User"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {submitError}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </StandardModal>
  );
};

export default EditUser;
