import StandardModal from "../../../../reusableComponents/StandardModal";
import axios from "axios";
import { UserData } from "../UserManagement";
import {
  Stack,
  TextField,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormControl,
  FormHelperText,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useForm, Controller } from "react-hook-form";
import { useState } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import MenuItem from "@mui/material/MenuItem";
import AddIcon from "@mui/icons-material/Add";

type Props = {
  userAdded: (user: UserData) => void;
  closeModal: () => void;
};

const CreateUser = ({ userAdded, closeModal }: Props) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<string>();

  const submitUser = async (data: UserData) => {
    setSubmitting(true);
    try {
      const response = await axios.post(
        "https://api.cwcontacts.com/users",
        data,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
          },
        }
      );
      userAdded(response.data);
      closeModal();
      console.log("response ", response);
    } catch (error: any) {
      setSubmitError(error?.response?.data);
      console.error("error creating user", error);
      setSubmitting(false);
    }
  };

  const {
    handleSubmit,
    control,
    formState: { isValid, errors },
  } = useForm<UserData>({ mode: "onChange" });

  return (
    <StandardModal title={`Add new User`}>
      <Stack spacing={2}>
        <Controller
          control={control}
          name="firstName"
          rules={{
            required: true,
            minLength: {
              value: 3,
              message: "Must be at least 3 characters",
            },
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              helperText={error ? error.message : null}
              size="small"
              error={!!error}
              onChange={onChange}
              value={value}
              fullWidth
              label={"First Name"}
              variant="outlined"
            />
          )}
        />
        <Controller
          control={control}
          name="lastName"
          rules={{
            required: true,
            minLength: {
              value: 3,
              message: "Must be at least 3 characters",
            },
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              helperText={error ? error.message : null}
              size="small"
              error={!!error}
              onChange={onChange}
              value={value}
              fullWidth
              label={"Last Name"}
              variant="outlined"
            />
          )}
        />
        <Controller
          control={control}
          name="email"
          rules={{
            required: true,
            pattern: {
              value:
                /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
              message: "Not a valid email address",
            },
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              helperText={error ? error.message : null}
              size="small"
              error={!!error}
              onChange={onChange}
              value={value}
              fullWidth
              label={"Email"}
              variant="outlined"
            />
          )}
        />
        <Controller
          control={control}
          name="password"
          rules={{
            required: true,
            minLength: {
              value: 7,
              message: "Must be at least 7 characters",
            },
            pattern: {
              value: /.*[!@#$%^&*()_+{}\[\]:;<>,.?~\-]/,
              message: "Password must contain at least one special character",
            },
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormControl variant="outlined">
              <InputLabel size="small" htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                size="small"
                sx={{ alignContent: "center", justifyContent: "center" }}
                type={showPassword ? "text" : "password"}
                error={errors.password !== undefined}
                onChange={onChange}
                value={value}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                      size="small"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
              {errors.password && (
                <FormHelperText error id="outlined-adornment-password">
                  {errors.password?.message}
                </FormHelperText>
              )}
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="roleId"
          rules={{
            required: true,
          }}
          defaultValue="85341695-e4d4-4abb-aa42-242131152b3b"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormControl fullWidth>
              <InputLabel>Role</InputLabel>

              <Select
                size="small"
                label="Role"
                value={value}
                onChange={onChange}
              >
                <MenuItem value="85341695-e4d4-4abb-aa42-242131152b3b">
                  Standard User
                </MenuItem>
                <MenuItem value="44820fb8-c928-499e-b5d0-57c5444457ce">
                  Admin
                </MenuItem>
              </Select>
            </FormControl>
          )}
        />

        <LoadingButton
          onClick={handleSubmit(submitUser)}
          startIcon={<AddIcon />}
          variant="contained"
          disabled={!isValid}
          loadingPosition="start"
          loading={submitting}
          sx={{ width: "fit-content" }}
        >
          Create User
        </LoadingButton>
      </Stack>
      <Dialog
        open={submitError !== undefined}
        onClose={() => setSubmitError(undefined)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Error Creating User"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {submitError}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </StandardModal>
  );
};

export default CreateUser;
