import { createContext, useContext, useState, PropsWithChildren } from "react";

export interface Me {
  uuid: string;
  roleId: string;
  name: string;
}

interface UserContextType {
  user: Me | null;
  setUser: React.Dispatch<React.SetStateAction<Me | null>>;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUserContext must be used within a UserProvider");
  }
  return context;
};

export const UserProvider = ({ children }: PropsWithChildren<{}>) => {
  const [user, setUser] = useState<Me | null>(null);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};
