import Box from "@mui/material/Box/Box";
import Button from "@mui/material/Button/Button";
import TablePagination from "@mui/material/TablePagination/TablePagination";

interface ContactFooterProps {
  isMobile: boolean;
  handleDownload: () => Promise<void>;
  totalPeople: number;
  rowLimit: number;
  setRowLimit: React.Dispatch<React.SetStateAction<number>>;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  loading: boolean;
}

const ContactFooter = ({
  isMobile,
  handleDownload,
  totalPeople,
  rowLimit,
  setRowLimit,
  page,
  setPage,
  loading,
}: ContactFooterProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isMobile ? "column-reverse" : "row",
        alignItems: isMobile ? "revert" : "normal",
        justifyContent: "space-between",
        paddingTop: "1rem",
        width: "100%",
      }}
    >
      <Button variant="contained" onClick={handleDownload}>
        {`Download (${totalPeople})`}
      </Button>

      <TablePagination
        labelRowsPerPage="Rows: "
        sx={{
          overflow: "hidden",
          position: "relative",
          width: "auto",
        }}
        rowsPerPageOptions={[25, 50, 100]}
        count={totalPeople}
        rowsPerPage={rowLimit}
        page={page}
        onPageChange={(e, newPage) => {
          newPage >= 0 && setPage(newPage);
        }}
        onRowsPerPageChange={(e) => setRowLimit(+e.target.value)}
        align="right"
        component="div"
        backIconButtonProps={{ disabled: loading || page === 0 }}
        nextIconButtonProps={{
          disabled: loading || page === Math.round(totalPeople / rowLimit),
        }}
      />
    </Box>
  );
};

export default ContactFooter;
