import Button from "@mui/material/Button";
import axios from "axios";
import { useState, useEffect } from "react";
import {
  Typography,
  Backdrop,
  CircularProgress,
  Stack,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Box,
} from "@mui/material";
import MoreIcon from "@mui/icons-material/MoreVert";
import React from "react";
import DeleteUser from "./modals/DeleteUser";
import CreateUser from "./modals/CreateUser";
import EditUser from "./modals/EditUser";

export type UserData = {
  uuid?: string;
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  roleId: string;
  roleName?: string;
};

enum UserAction {
  NONE,
  CREATE,
  DELETE,
  EDIT,
}

const UserManagement = () => {
  const [users, setUsers] = useState<UserData[]>();
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [focusUser, setFocusUser] = useState<UserData>();
  const [userAction, setUserAction] = useState<UserAction>(UserAction.NONE);

  const userRemoved = (user: UserData) => {
    setUsers(users?.filter((u: UserData) => u.uuid !== user.uuid));
  };
  const userAdded = (user: UserData) => {
    if (users) setUsers([...users, user]);
    else setUsers([user]);
  };
  const userPatched = (user: UserData) => {
    setUsers(
      users?.map((u) => {
        if (u.uuid === user.uuid) return user;
        else return u;
      })
    );
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  const closeUserMenu = () => {
    setMenuAnchor(null);
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`https://api.cwcontacts.com/users`, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
          },
        });
        setUsers(response.data);
        console.log("got users", response);
      } catch (error) {
        console.error("error fetching users", error);
      }
    };
    fetchUsers();
  }, []);

  if (!users) {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  const modalContent = () => {
    switch (userAction) {
      case UserAction.CREATE:
        return (
          <CreateUser
            closeModal={() => setUserAction(UserAction.NONE)}
            userAdded={userAdded}
          />
        );
      case UserAction.EDIT:
        if (focusUser)
          return (
            <EditUser
              closeModal={() => setUserAction(UserAction.NONE)}
              user={focusUser}
              userPatched={userPatched}
            />
          );
        else return <></>;
      case UserAction.DELETE:
        return (
          <DeleteUser
            closeModal={() => setUserAction(UserAction.NONE)}
            user={focusUser}
            userRemoved={userRemoved}
          />
        );
      default:
        return <div></div>;
    }
  };

  return (
    <Box sx={{ width: "100%", maxWidth: "700px" }}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h5" gutterBottom>
          Manage Users
        </Typography>
        <Button
          variant="contained"
          onClick={() => setUserAction(UserAction.CREATE)}
        >
          + Add User
        </Button>
      </Box>
      <Stack sx={{ padding: "1rem" }} spacing={2}>
        {users.map((u: UserData) => (
          <Box
            key={u.uuid}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0.4rem",
              borderBottom: "solid 1px lightgray",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Chip label={u.roleName} color="primary" />
              <Typography>{`${u.firstName} ${u.lastName}`}</Typography>
            </Box>
            <IconButton
              onClick={(e) => {
                handleMenu(e);
                setFocusUser(u);
              }}
              size="large"
              aria-label="display more actions"
              edge="end"
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        ))}
      </Stack>
      <Menu
        id="menu-appbar"
        anchorEl={menuAnchor}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(menuAnchor)}
        onClose={closeUserMenu}
      >
        <MenuItem
          onClick={() => {
            closeUserMenu();
            setUserAction(UserAction.EDIT);
          }}
        >
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            closeUserMenu();
            setUserAction(UserAction.DELETE);
          }}
        >
          Remove
        </MenuItem>
      </Menu>
      <Modal
        open={userAction !== UserAction.NONE}
        onClose={() => setUserAction(UserAction.NONE)}
      >
        {modalContent()}
      </Modal>
    </Box>
  );
};

export default UserManagement;
