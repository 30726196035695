import Box from "@mui/material/Box/Box";
import Button from "@mui/material/Button/Button";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import Modal from "@mui/material/Modal/Modal";
import Typography from "@mui/material/Typography/Typography";
import { MutableRefObject } from "react";

interface DownloadModalProps {
  progress: number | null;
  setProgress: React.Dispatch<React.SetStateAction<number | null>>;
  totalPeople: number;
  canceledRef: MutableRefObject<boolean>;
}

const DownloadModal = ({
  progress,
  setProgress,
  totalPeople,
  canceledRef,
}: DownloadModalProps) => {
  return (
    <Modal open={progress !== null}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography>{`Downloading ${totalPeople} contacts`} </Typography>
        <Box
          sx={{
            position: "relative",
            display: "inline-flex",
            marginTop: "1rem",
          }}
        >
          <CircularProgress
            size={60}
            color="success"
            value={progress ? progress : 0}
          />
          <Box
            sx={{
              width: "100%",
              position: "absolute",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="caption" component="div" sx={{}}>
              {progress && `${Math.round(progress)}%`}
            </Typography>
          </Box>
        </Box>
        <Button
          variant="contained"
          color="warning"
          sx={{ marginTop: "1rem" }}
          onClick={() => {
            canceledRef.current = true;
            setProgress(null);
          }}
        >
          Cancel
        </Button>
      </Box>
    </Modal>
  );
};

export default DownloadModal;
