import {
  Box,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";

import classes from "./MatchingFields.module.scss";
import { Matching } from "../ImportData";
import { contactFields } from "../importHelper";
import MatchingHeader from "./MatchingHeader";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface MatchingFieldsProps {
  csvHeader: string[];
  matching: Matching;
  setMatching: React.Dispatch<React.SetStateAction<Matching>>;
  inputSource?: string;
  setInputSource: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const MatchingFields = ({
  csvHeader,
  matching,
  setMatching,
  inputSource,
  setInputSource,
}: MatchingFieldsProps) => {
  const handleChange = (
    dbField: string,
    csvField: number | number[] | null
  ) => {
    setMatching({ ...matching, [dbField]: csvField });
  };

  const convertToObject = (array: string[]) => {
    if (!array?.length) return [];
    const result: { name: string; index: number }[] = [];
    for (let i = 0; i < array.length; i++) {
      result.push({ name: array[i], index: i });
    }
    return result;
  };

  const headerObject = convertToObject(csvHeader);
  return (
    <Box sx={{ width: "100%", height: "65vh" }}>
      <MatchingHeader
        inputSource={inputSource}
        setInputSource={setInputSource}
      />
      {contactFields
        .filter((field) => field.label !== "Person Type")
        .map((field, index) => {
          return (
            field.label !== "Source" && (
              <div className={classes["matching_row"]} key={index}>
                <div className={classes["db_column"]}>{field.label}</div>
                {field.multipleSelect ? (
                  <FormControl
                    sx={{ m: 1, width: "50%", position: "relative" }}
                  >
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={matching[field.label] || []}
                      onChange={(e) =>
                        handleChange(field.label, e.target.value as number)
                      }
                      renderValue={(selected) => {
                        return (
                          Array.isArray(selected) &&
                          selected
                            .map(
                              (index) =>
                                headerObject.find(
                                  (header) => header.index === +index
                                )?.name
                            )
                            .join(", ")
                        );
                      }}
                      MenuProps={MenuProps}
                      sx={{ height: "30px" }}
                    >
                      {headerObject.map((item) => {
                        return (
                          <MenuItem key={item.index} value={item.index}>
                            <ListItemText primary={item.name} />
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                ) : (
                  <FormControl
                    sx={{ m: 1, width: "50%", position: "relative" }}
                  >
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      value={
                        matching[field.label] || matching[field.label] === 0
                          ? matching[field.label]
                          : ""
                      }
                      onChange={(e) =>
                        handleChange(field.label, e.target.value as number)
                      }
                      MenuProps={MenuProps}
                      sx={{ height: "30px" }}
                    >
                      {headerObject.map((item) => {
                        return (
                          <MenuItem key={item.index} value={item.index}>
                            <ListItemText primary={item.name} />
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                )}
              </div>
            )
          );
        })}
    </Box>
  );
};

export default MatchingFields;
