import axios from "axios";
import { Filters, Person, RequestPeopleDataParams } from "cwpersontypes";

export interface GetPeopleResult {
  peopleData: Person[];
  totalPeople: number;
}

export const getPeople = async (
  filters: Filters,
  fields: string[]
): Promise<GetPeopleResult> => {
  const body: RequestPeopleDataParams = {
    filters: filters,
    fields: fields,
  };

  try {
    const response: any = await axios.post(
      "https://api.cwcontacts.com/contacts",
      body,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.log("error get people", error);
    return { peopleData: [], totalPeople: 0 };
  }
};
