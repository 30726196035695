import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  Button,
  SelectChangeEvent,
  Box,
} from "@mui/material";
import { useState } from "react";
import {
  ContactFieldsType,
  contactFields,
} from "../../dashBoard/settings/importData/importHelper";

interface SelectColumnsProps {
  columns: ContactFieldsType[];
  setShowColumns: React.Dispatch<React.SetStateAction<number[]>>;
}

const SelectColumns = ({ columns, setShowColumns }: SelectColumnsProps) => {
  // const [isSaved, setIsSaved] = useState<boolean>(false);
  const [selectedColumns, setSelectedColumns] = useState<number[]>([2, 3]);

  const handleSave = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    // setIsSaved(true);
    setShowColumns(selectedColumns);
  };

  const handleSelectColumn = (e: SelectChangeEvent<number[]>) => {
    // setIsSaved(false);
    const value = e.target.value;
    value.length && setSelectedColumns(e.target.value as number[]);
  };

  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <FormControl sx={{ m: 1, width: 300, float: "left" }}>
        <InputLabel>Columns Display </InputLabel>
        <Select
          multiple
          sx={{ "& .MuiSelect-select": { padding: "0.5rem" } }}
          value={selectedColumns}
          displayEmpty
          onChange={handleSelectColumn}
          label="select columns"
          renderValue={(selected) =>
            selected.map((i) => contactFields[i].label).join(", ")
          }
        >
          {columns.map(
            (column, index) =>
              column.label !== "First Name" &&
              column.label !== "Last Name" && (
                <MenuItem key={column.label} value={index}>
                  <Checkbox
                    checked={selectedColumns.findIndex((c) => c === index) >= 0}
                  />
                  {column.label}
                </MenuItem>
              )
          )}
        </Select>
      </FormControl>
      <div
        style={{
          textAlign: "right",
          display: "flex",
          alignItems: "center",
          gap: "1rem",
          justifyContent: "flex-end",
        }}
      >
        {/* {isSaved ? (
          <div style={{ color: "red" }}>Saved</div>
        ) : (
          <div style={{ color: "red" }}>Change need to be saved</div>
        )} */}
        <Button
          variant="contained"
          onClick={handleSave}
          sx={{ marginRight: "0.5rem" }}
        >
          Save
        </Button>
      </div>
    </Box>
  );
};

export default SelectColumns;
