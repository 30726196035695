import { useRef, useState } from "react";
import classes from "./ImportData.module.scss";
import DropZone from "./DropZone";
import MatchingFields from "./matchingFields/MatchingFields";
import {
  Box,
  Button,
  LinearProgress,
  LinearProgressProps,
  Modal,
  Typography,
} from "@mui/material";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import { transformData } from "./importHelper";
import UploadOptions from "./UploadOptions";
import { Person } from "cwpersontypes";

export type Matching = {
  [key: string]: number | number[] | null;
};

export type ContactTypes = "Vendor" | "Agent";

const ImportData = () => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [nextReady, setNextReady] = useState<boolean>(false);
  const [matching, setMatching] = useState<Matching>({});
  const [inputSource, setInputSource] = useState<string>();
  const [dnc, setDnc] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [contactType, setContactType] = useState<ContactTypes | null>(null);
  const [progress, setProgress] = useState<number>(0);

  const csvData = useRef<string[][]>([]);

  function splitArrayIntoChunks(array: Person[], maxChunkSizeInBytes: number) {
    const chunks = [];
    let currentChunk = [];
    let currentSize = 0;

    for (const item of array) {
      const itemSizeInBytes = JSON.stringify(item).length;

      if (currentSize + itemSizeInBytes > maxChunkSizeInBytes) {
        chunks.push(currentChunk);
        currentChunk = [];
        currentSize = 0;
      }

      currentChunk.push(item);
      currentSize += itemSizeInBytes;
    }

    if (currentChunk.length > 0) {
      chunks.push(currentChunk);
    }

    return chunks;
  }

  // function delay(milliseconds: number) {
  //   return new Promise((resolve) => {
  //     setTimeout(resolve, milliseconds);
  //   });
  // }

  const handleUpload = async () => {
    setProgress(0);
    setLoading(true);
    const transformedPeople =
      inputSource &&
      (await transformData(
        csvData,
        inputSource,
        dnc,
        matching,
        contactType || "Agent"
      ));

    const maxSizeInBytes = 4 * 1024 * 1024; // 4MB

    const peopleParts = transformedPeople
      ? splitArrayIntoChunks(transformedPeople, maxSizeInBytes)
      : [];

    //do promise all may have error duplicate key on DB  - so need to do one by one
    let completed = 0;
    let successed = 0;
    let failed = 0;
    for (const people of peopleParts) {
      try {
        await axios.put(
          "https://api.cwcontacts.com/contacts/upload",
          JSON.stringify(people)
        );

        successed += people.length;
      } catch (err) {
        console.log("error on upload data", err);
        failed += people.length;
      }
      completed++;
      const percentage = Math.round((completed / peopleParts.length) * 100);
      setProgress(percentage);
    }

    console.log("successed:", successed, " - failed: ", failed);

    setLoading(false);
    setActiveStep(0);
    setMatching({});
  };

  function LinearProgressWithLabel(
    props: LinearProgressProps & { value: number }
  ) {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

  const modalStyle = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  return (
    <>
      {!contactType && (
        <div>
          <UploadOptions
            handleChange={(selected: ContactTypes) => setContactType(selected)}
          />
        </div>
      )}

      {contactType && (
        <div className={classes["import-container"]}>
          <h1>{`Upload ${contactType}s`}</h1>
          {/* {openArlet && (
            <Notification
              open={openArlet}
              handleClose={() => setOpenAlert(false)}
              status={status}
            />
          )} */}
          <div className={classes["import-body"]}>
            {activeStep === 0 && (
              <DropZone
                setNextReady={setNextReady}
                csvData={csvData}
                setInputSource={setInputSource}
              />
            )}
            {activeStep === 1 && (
              <MatchingFields
                csvHeader={csvData.current[0]}
                matching={matching}
                setMatching={setMatching}
                inputSource={inputSource}
                setInputSource={setInputSource}
              />
            )}
          </div>

          {activeStep === 1 && (
            <div className={classes["dnc_checkbox"]}>
              <input
                type="checkbox"
                onChange={(e) => setDnc(e.target.checked)}
              />
              <label>Do not contact</label>
            </div>
          )}

          <div className={classes["button-group"]}>
            {activeStep === 0 && (
              <>
                <Button
                  variant="contained"
                  onClick={() => setContactType(null)}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  onClick={() => setActiveStep(activeStep + 1)}
                  disabled={!nextReady}
                >
                  Next
                </Button>
              </>
            )}

            {activeStep === 1 && (
              <>
                <Button
                  variant="contained"
                  sx={{ marginRight: "auto " }}
                  onClick={() => setActiveStep(activeStep - 1)}
                >
                  Back
                </Button>
                <LoadingButton
                  variant="contained"
                  sx={{ marginLeft: "auto" }}
                  onClick={handleUpload}
                  disabled={!nextReady}
                  loading={loading}
                >
                  Upload
                </LoadingButton>
              </>
            )}
          </div>

          <Modal
            open={loading}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={{ ...modalStyle, width: 400 }}>
              Progress:
              <LinearProgressWithLabel value={progress} />
              <Box sx={{ textAlign: "center" }}>
                <Button variant="contained" onClick={() => setLoading(false)}>
                  Close
                </Button>
              </Box>
            </Box>
          </Modal>
        </div>
      )}
    </>
  );
};

export default ImportData;
