import { Route, Routes } from "react-router-dom";
import NavBar from "./navbar/Navbar";
import People from "../people/People";
import Settings from "./settings/Settings";
import { Box } from "@mui/material";
import BotStatus from "../bot/BotStatus";

const Dashboard = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <NavBar />
      <Box style={{ marginTop: "64px", height: "100%" }}>
        <Routes>
          <Route path="/settings/*" element={<Settings />} />
          <Route path="/bots" element={<BotStatus />} />
          <Route path="/" element={<People />} />
        </Routes>
      </Box>
    </Box>
  );
};

export default Dashboard;
