import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import PeopleContact from "./peopleContact/PeopleContact";
import { Filters, PersonSource } from "cwpersontypes";
import FilterDawer from "./filter/FilterDrawer";
import PeopleContext from "../contexts/PeopleContext";
import { getSources } from "../api/sources";

const People = () => {
  const [filters, setFilters] = useState<Filters>({});
  const [leadSources, setLeadSources] = useState<PersonSource[]>([]);

  useEffect(() => {
    const getLeadSource = async () => {
      const sources: PersonSource[] = await getSources();
      setLeadSources(sources);
    };

    getLeadSource();
  }, []);

  return (
    <PeopleContext.Provider value={{ filters, setFilters }}>
      <Box position="relative" sx={{ display: "flex", height: "100%" }}>
        <FilterDawer leadSources={leadSources} />
        <PeopleContact leadSources={leadSources} />
      </Box>
    </PeopleContext.Provider>
  );
};

export default People;
