export const drawer_open_button = {
  backgroundColor: "#1976d2",
  position: "fixed",
  bottom: "12%",
  right: "10%",
  zIndex: "2",
  color: "white",
};

export const drawer_close_button = {
  backgroundColor: "lightgray",
  position: "absolute",
  bottom: "5%",
  right: 0,
  zIndex: "2",
  height: "5rem",
  borderRadius: " 0.5rem 0 0 0.5rem",
  width: "3rem",
  top: "80%",
};
