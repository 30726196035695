import { Person } from "cwpersontypes";
import Papa from "papaparse";
import { contactFields } from "../../dashBoard/settings/importData/importHelper";

export const getFieldsForRequest = (selectedColumns: number[]): string[] => {
  const fields: string[] = [];
  selectedColumns.forEach((column) => {
    if (contactFields[column].isPersonData) {
      !fields.includes("personDatas") && fields.push("personDatas");
    } else {
      fields.push(contactFields[column].field);
    }
  });

  return fields;
};

export const convertToCsvData = (people: Person[]) => {
  type RowData = {
    [key: string]: string;
  };
  const rows = [];

  let fields: string[] = ["First Name", "Last Name"];
  let emailColumnsSet: Set<string> = new Set();
  let phoneColumnsSet: Set<string> = new Set();
  let personTypeSet: Set<string> = new Set();
  let sourceColumnsSet: Set<string> = new Set();
  let socialMediaColumnsSet: Set<string> = new Set();
  let photoColumnsSet: Set<string> = new Set();
  let activeCityColumnsSet: Set<string> = new Set();
  let personDataColumnsSet: Set<string> = new Set();

  for (const person of people) {
    const rowData: RowData = {};
    rowData["First Name"] = person.firstName;
    rowData["Last Name"] = person.lastName;

    if (person.emailAddresses) {
      person.emailAddresses.forEach((email, index) => {
        let columnName = `Email_${index + 1}`;
        if (emailColumnsSet.size < 3) {
          emailColumnsSet.add(columnName);
        }
        rowData[columnName] = email.address || "";
      });
    }
    if (person.phoneNumbers) {
      person.phoneNumbers.forEach((phone, index) => {
        let columnName = `Phone_${index + 1}`;
        if (phoneColumnsSet.size < 3) {
          phoneColumnsSet.add(columnName);
        }
        rowData[columnName] = phone.number || "";
      });
    }
    if (person.personType) {
      personTypeSet.add("Person Type");
      rowData["Person Type"] = person.personType;
    }
    if (person.sources) {
      person.sources.forEach((source, index) => {
        let columnName = `Source_${index + 1}`;
        if (sourceColumnsSet.size < 3) {
          sourceColumnsSet.add(columnName);
        }
        rowData[columnName] = source.sourceName || "";
      });
    }
    if (person.socialMediaLinks) {
      person.socialMediaLinks.forEach((sm) => {
        if (sm.socialMediaType) {
          if (!socialMediaColumnsSet.has(sm.socialMediaType)) {
            socialMediaColumnsSet.add(sm.socialMediaType);
          }
          rowData[sm.socialMediaType] = sm.href;
        }
      });
    }
    if (person.photos) {
      person.photos.forEach((photo, index) => {
        let columnName = `Photo_${index + 1}`;
        if (photoColumnsSet.size < 3) {
          photoColumnsSet.add(columnName);
        }
        rowData[columnName] = photo.href || "";
      });
    }
    if (person.activeCities) {
      person.activeCities.forEach((city, index) => {
        let columnName = `City_${index + 1}`;
        if (activeCityColumnsSet.size < 3) {
          activeCityColumnsSet.add(columnName);
        }
        rowData[columnName] = `${city.cityName}, ${city.region} ` || "";
      });
    }
    if (person.personDatas) {
      person.personDatas.forEach((pd, index) => {
        if (pd.personFieldName) {
          if (!personDataColumnsSet.has(pd.personFieldName)) {
            personDataColumnsSet.add(pd.personFieldName);
          }
          rowData[pd.personFieldName] = pd.value;
        }
      });
    }
    rows.push(rowData);
  }

  fields = fields.concat(
    Array.from(emailColumnsSet),
    Array.from(phoneColumnsSet),
    Array.from(personTypeSet),
    Array.from(sourceColumnsSet),
    Array.from(socialMediaColumnsSet),
    Array.from(photoColumnsSet),
    Array.from(activeCityColumnsSet),
    Array.from(personDataColumnsSet)
  );
  const csvData = Papa.unparse(rows, { columns: fields });

  return csvData;
};
