import Box from "@mui/material/Box/Box";
import IconButton from "@mui/material/IconButton/IconButton";
import ClearIcon from "@mui/icons-material/Clear";

import classes from "./ContactHeader.module.scss";
import SelectColumns from "./SelectColumns";
import { MutableRefObject } from "react";
import React from "react";
import { ContactFieldsType } from "../../dashBoard/settings/importData/importHelper";

interface ContactHeaderProps {
  selectedFilters: MutableRefObject<string | null>;
  setFilters: any;
  contactFields: ContactFieldsType[];
  setShowColumns: React.Dispatch<React.SetStateAction<number[]>>;
  isMobile: boolean;
}

const ContactHeader = ({
  selectedFilters,
  setFilters,
  contactFields,
  setShowColumns,
  isMobile,
}: ContactHeaderProps) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      {selectedFilters.current ? (
        <div className={classes["current_filers"]}>
          <div>{selectedFilters.current}</div>
          <IconButton size="small" onClick={() => setFilters({})}>
            <ClearIcon fontSize="small" />
          </IconButton>
        </div>
      ) : (
        <div style={{ height: "2rem" }}></div>
      )}

      {!isMobile && (
        <SelectColumns
          columns={contactFields}
          setShowColumns={setShowColumns}
        />
      )}
    </Box>
  );
};

export default ContactHeader;
