import {
  ActiveCity,
  Email,
  Person,
  PersonFieldId,
  Phone,
  Photo,
  SocialMedia,
  SocialMediaTypeId,
} from "cwpersontypes";
import { ContactTypes, Matching } from "./ImportData";
import { createSource, getSources } from "../../../api/sources";

export interface ContactFieldsType {
  label: string;
  field: string;
  multipleSelect: boolean;
  peopleContact?: boolean;
  isPersonData: boolean;
}

export const contactFields: ContactFieldsType[] = [
  {
    label: "First Name",
    field: "firstName",
    multipleSelect: false,
    isPersonData: false,
  },
  {
    label: "Last Name",
    field: "lastName",
    multipleSelect: false,
    isPersonData: false,
  },
  {
    label: "Email",
    field: "emailAddresses",
    multipleSelect: true,
    isPersonData: false,
  },
  {
    label: "Phone",
    field: "phoneNumbers",
    multipleSelect: true,
    isPersonData: false,
  },
  {
    label: "Source",
    field: "sources",
    multipleSelect: false,
    isPersonData: false,
  },
  {
    label: "Person Type",
    field: "personType",
    multipleSelect: false,
    isPersonData: false,
  },
  {
    label: "Photos",
    field: "photos",
    multipleSelect: true,
    isPersonData: false,
  },
  {
    label: "Active Cities",
    field: "activeCities",
    multipleSelect: true,
    isPersonData: false,
  },
  {
    label: "Social Media",
    field: "socialMediaLinks",
    multipleSelect: true,
    isPersonData: false,
  },
  {
    label: "Broker",
    field: "broker",
    multipleSelect: false,
    isPersonData: true,
  },
  {
    label: "Company",
    field: "company",
    multipleSelect: false,
    isPersonData: true,
  },
  { label: "MSL", field: "msl", multipleSelect: false, isPersonData: true },
  {
    label: "Office Address",
    field: "office address",
    multipleSelect: false,
    isPersonData: true,
  },
  {
    label: "Is Team Member",
    field: "is team member",
    multipleSelect: false,
    isPersonData: true,
  },
  {
    label: "Website",
    field: "website",
    multipleSelect: false,
    isPersonData: true,
  },
  { label: "Bio", field: "bio", multipleSelect: false, isPersonData: true },
  {
    label: "First Year",
    field: "first year",
    multipleSelect: false,
    isPersonData: true,
  },
  {
    label: "Last Sold Date",
    field: "last sold date",
    multipleSelect: false,
    isPersonData: true,
  },
  {
    label: "Sold Count",
    field: "sold count",
    multipleSelect: false,
    isPersonData: true,
  },
  {
    label: "Sold Min Price",
    field: "sold min price",
    multipleSelect: false,
    isPersonData: true,
  },
  {
    label: "Address",
    field: "address",
    multipleSelect: true,
    isPersonData: true,
  },
  {
    label: "Sold Max Price",
    field: "sold max price",
    multipleSelect: false,
    isPersonData: true,
  },
];

const PersonTypeId = {
  AGENT: "f9dc9e0c-84b4-4838-b699-e737e665997c",
  VENDOR: "79dca096-f954-45db-8d33-379d896a105e",
};

export const getSocialMediaTypeId = (href: string) => {
  const hrefLowerCase = href.toLowerCase();
  switch (true) {
    case hrefLowerCase.includes("facebook"):
      return SocialMediaTypeId.FACEBOOK;
    case hrefLowerCase.includes("twitter"):
      return SocialMediaTypeId.TWITTER;
    case hrefLowerCase.includes("linkedin"):
      return SocialMediaTypeId.LINKEDIN;
    case hrefLowerCase.includes("instagram"):
      return SocialMediaTypeId.INSTAGRAM;
    case hrefLowerCase.includes("tiktok"):
      return SocialMediaTypeId.TIKTOK;
    case hrefLowerCase.includes("youtube"):
      return SocialMediaTypeId.YOUTUBE;
    case hrefLowerCase.includes("whatsapp"):
      return SocialMediaTypeId.WHATSAPP;
    case hrefLowerCase.includes("wechat"):
      return SocialMediaTypeId.WECHAT;
  }
};

export const getSourceId = async (inputSource: string) => {
  const dbLeadSources = await getSources();
  let sourceExisted = dbLeadSources.find((s) => {
    if (s.sourceName && inputSource) {
      return s.sourceName.toLowerCase().includes(inputSource?.toLowerCase());
    }
    return false;
  });

  if (sourceExisted?.uuid) {
    return sourceExisted.uuid;
  } else if (inputSource) {
    const newSource = await createSource(inputSource);
    return newSource.uuid;
  }
};

const getPersonTypeId = (contactType: ContactTypes) => {
  switch (contactType) {
    case "Vendor":
      return PersonTypeId.VENDOR;
    default:
      return PersonTypeId.AGENT;
  }
};

export const transformData = async (
  csvData: React.MutableRefObject<string[][]>,
  inputSource: string,
  dnc: boolean,
  matching: Matching,
  contactType: ContactTypes
) => {
  if (!csvData.current.length) return;
  const csvPeople = csvData.current.slice(1);
  const transformedPeople: Person[] = [];
  const sourceId = inputSource && (await getSourceId(inputSource));

  for (let person of csvPeople) {
    // if (person.length !== csvData.current[0].length) continue;
    const transformedPerson: Person = {
      firstName: "",
      lastName: "",
      personTypeId: getPersonTypeId(contactType),
      dnc: false,
      sources: [],
      emailAddresses: [],
      phoneNumbers: [],
      socialMediaLinks: [],
      personDatas: [],
      activeCities: [],
      photos: [],
    };

    const personDatas = [];
    for (let field of contactFields) {
      if (dnc) {
        transformedPerson.dnc = true;
      }
      const columnIndex = matching[field.label];
      if (field.label === "First Name" && typeof columnIndex === "number") {
        transformedPerson.firstName = person[columnIndex];
      } else if (
        field.label === "Last Name" &&
        typeof columnIndex === "number"
      ) {
        transformedPerson.lastName = person[columnIndex];
      } else if (field.label === "Email" && Array.isArray(columnIndex)) {
        const emails: Email[] = [];
        columnIndex.forEach(
          (i) =>
            person[i] &&
            emails.push({
              address: person[i],
            })
        );
        transformedPerson.emailAddresses = emails;
      } else if (field.label === "Phone" && Array.isArray(columnIndex)) {
        const phones: Phone[] = [];
        columnIndex.forEach(
          (i) =>
            person[i] &&
            phones.push({
              number: person[i],
            })
        );
        transformedPerson.phoneNumbers = phones;
      } else if (field.label === "Social Media" && Array.isArray(columnIndex)) {
        const socialMedias: SocialMedia[] = [];

        columnIndex.forEach((i) => {
          const href = person[i];
          const smTypeId = getSocialMediaTypeId(href);

          smTypeId &&
            socialMedias.push({
              socialMediaTypeId: smTypeId,
              href: href,
            });
        });
        transformedPerson.socialMediaLinks = socialMedias;
      } else if (field.label === "Photos" && Array.isArray(columnIndex)) {
        const photos: Photo[] = [];

        columnIndex.forEach((i) => {
          const href = person[i];
          href && photos.push({ href: href });
        });
        transformedPerson.photos = photos;
      } else if (
        field.label === "Active Cities" &&
        Array.isArray(columnIndex)
      ) {
        const activeCities: ActiveCity[] = [];

        columnIndex.forEach((i) => {
          if (person[i]) {
            const split = person[i].replace(/\s+/g, "").split(",");
            const city = split[0];
            const region = split[1];

            if (city || region) {
              activeCities.push({ cityName: city, region: region });
            }
          }
        });
        transformedPerson.activeCities = activeCities;
      } else if (field.label === "Broker" && typeof columnIndex === "number") {
        person[columnIndex] &&
          personDatas.push({
            personFieldId: PersonFieldId.BROKER,
            value: person[columnIndex],
          });
      } else if (field.label === "Company" && typeof columnIndex === "number") {
        person[columnIndex] &&
          personDatas.push({
            personFieldId: PersonFieldId.COMPANY,
            value: person[columnIndex],
          });
      } else if (field.label === "MSL" && typeof columnIndex === "number") {
        person[columnIndex] &&
          personDatas.push({
            personFieldId: PersonFieldId.MLS,
            value: person[columnIndex],
          });
      } else if (
        field.label === "Office Address" &&
        typeof columnIndex === "number"
      ) {
        person[columnIndex] &&
          personDatas.push({
            personFieldId: PersonFieldId.OFFICE_ADDRESS,
            value: person[columnIndex],
          });
      } else if (
        field.label === "Is Team Member" &&
        typeof columnIndex === "number"
      ) {
        person[columnIndex] &&
          personDatas.push({
            personFieldId: PersonFieldId.IS_TEAM_MEMBER,
            value: person[columnIndex],
          });
      } else if (field.label === "Website" && typeof columnIndex === "number") {
        person[columnIndex] &&
          personDatas.push({
            personFieldId: PersonFieldId.WEBSITE,
            value: person[columnIndex],
          });
      } else if (field.label === "Bio" && typeof columnIndex === "number") {
        person[columnIndex] &&
          personDatas.push({
            personFieldId: PersonFieldId.BIO,
            value: person[columnIndex],
          });
      } else if (
        field.label === "First Year" &&
        typeof columnIndex === "number"
      ) {
        person[columnIndex] &&
          personDatas.push({
            personFieldId: PersonFieldId.FIRST_YEAR,
            value: person[columnIndex],
          });
      } else if (
        field.label === "Last Sold Date" &&
        typeof columnIndex === "number"
      ) {
        person[columnIndex] &&
          personDatas.push({
            personFieldId: PersonFieldId.LAST_SOLD_DATE,
            value: person[columnIndex],
          });
      } else if (
        field.label === "Sold Count" &&
        typeof columnIndex === "number"
      ) {
        person[columnIndex] &&
          personDatas.push({
            personFieldId: PersonFieldId.SOLD_COUNT,
            value: person[columnIndex],
          });
      } else if (
        field.label === "Sold Min Price" &&
        typeof columnIndex === "number"
      ) {
        person[columnIndex] &&
          personDatas.push({
            personFieldId: PersonFieldId.SOLD_MIN_PRICE,
            value: person[columnIndex],
          });
      } else if (
        field.label === "Sold Max Price" &&
        typeof columnIndex === "number"
      ) {
        person[columnIndex] &&
          personDatas.push({
            personFieldId: PersonFieldId.SOLD_MAX_PRICE,
            value: person[columnIndex],
          });
      } else if (field.label === "Address" && Array.isArray(columnIndex)) {
        const address = columnIndex.map((i) => person[i]).join(" ");
        personDatas.push({
          personFieldId: PersonFieldId.ADDRESS,
          value: address,
        });
      }
    }
    if (sourceId) transformedPerson.sources = [{ sourceId: sourceId }];

    transformedPerson.personDatas = personDatas;

    //only push person have name and (email or phone number)
    if (
      (transformedPerson.firstName || transformedPerson.lastName) &&
      (transformedPerson.emailAddresses.length ||
        transformedPerson.phoneNumbers.length)
    ) {
      transformedPeople.push(transformedPerson);
    }
  }
  return transformedPeople;
};
