import { Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import Login from "./login/Login";
import { useEffect } from "react";
import Dashboard from "./dashBoard/DashBoard";
import axios from "axios";
import { useUserContext } from "./contexts/UserContext";

function App() {
  const { user, setUser } = useUserContext();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMe = async (token: string) => {
      try {
        const result = await axios.get("https://api.cwcontacts.com/users/me", {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUser(result.data);
      } catch (error) {
        navigate("/login");
      }
    };

    if (window.location.pathname !== "/login" && !user) {
      const jwtToken = localStorage.getItem("userToken");
      if (!jwtToken) {
        navigate("/login");
      } else {
        fetchMe(jwtToken);
      }
    }
  }, [navigate, setUser, user]);

  if (
    !localStorage.getItem("userToken") &&
    window.location.pathname !== "/login"
  )
    return <></>;

  return (
    <div className="App">
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/*" element={<Dashboard />} />
      </Routes>
    </div>
  );
}

export default App;
