import Button from "@mui/material/Button";
import { ContactTypes } from "./ImportData";

type Props = {
  handleChange: (selected: ContactTypes) => void;
};

const UploadOptions = ({ handleChange }: Props) => {
  const customStyle = {
    minWidth: "250px",
    minHeight: "100px",
    fontSize: "1.5rem",
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h1>Select type of people to upload</h1>
      <div
        style={{
          display: "flex",
          gap: "2rem",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          onClick={() => handleChange("Agent")}
          style={customStyle}
          variant="contained"
        >
          Agents
        </Button>
        <Button
          onClick={() => handleChange("Vendor")}
          style={customStyle}
          variant="contained"
        >
          Vendors
        </Button>
      </div>
    </div>
  );
};

export default UploadOptions;
