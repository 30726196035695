import { Box, Button, CircularProgress, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";

import classes from "./Validaion.module.scss";

interface EmailStatus {
  status: boolean;
  validated: number;
  unvalidate: number;
  balance?: number;
}

const Validation = () => {
  const [emailStatus, setEmailStatus] = useState<EmailStatus>({
    status: true,
    validated: 0,
    unvalidate: 0,
    balance: 0,
  });
  const [phoneStatus, setPhoneStatus] = useState<EmailStatus>({
    status: true,
    validated: 0,
    unvalidate: 0,
  });

  const [loading, setLoading] = useState<boolean>(true);

  const baseUrl = "https://api.cwcontacts.com/validation";

  useEffect(() => {
    const getEmailStatus = async () => {
      const status = await axios.get(`${baseUrl}/emailstatus`);
      setEmailStatus(status.data);
    };

    const getPhoneStatus = async () => {
      const status = await axios.get(`${baseUrl}/phonestatus`);
      setPhoneStatus(status.data);
    };

    const getStatus = async () => {
      await getEmailStatus();
      await getPhoneStatus();

      setLoading(false);
    };

    getStatus();
  }, []);

  const handleValidateEmail = async () => {
    await axios.post(`${baseUrl}/validateemail`);
    setEmailStatus((prevState) => ({ ...prevState, status: true }));
  };

  const handleValidatePhone = async () => {
    await axios.post(`${baseUrl}/validatephone`);
    setPhoneStatus((prevState) => ({ ...prevState, status: true }));
  };

  return (
    <>
      {loading ? (
        <Box sx={{ marginTop: "10vh" }}>
          <CircularProgress size={80} style={{ animationDuration: "0.5s" }} />
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h3" component="h3">
            Validation Status
          </Typography>

          <div className={classes["validation_status"]}>
            <div className={classes["status_container"]}>
              <h2>Email</h2>
              <div className={classes["status_table"]}>
                <div className={classes["table__row"]}>
                  <div className={classes["table__field"]}>Status</div>
                  <div
                    className={classes["table__value"]}
                    style={{
                      color: emailStatus.status ? "green" : "red",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                    }}
                  >
                    {emailStatus.status ? "Running" : "Stop"}
                  </div>
                </div>
                <div className={classes["table__row"]}>
                  <div className={classes["table__field"]}>
                    Validated Emails
                  </div>
                  <div className={classes["table__value"]}>
                    {emailStatus.validated}
                  </div>
                </div>
                <div className={classes["table__row"]}>
                  <div className={classes["table__field"]}>
                    Unvalidated Emails
                  </div>
                  <div className={classes["table__value"]}>
                    {emailStatus.unvalidate}
                  </div>
                </div>
                <div className={classes["table__row"]}>
                  <div className={classes["table__field"]}>Proofy Balance</div>
                  <div className={classes["table__value"]}>
                    {emailStatus.balance}
                  </div>
                </div>

                <Button
                  variant="contained"
                  sx={{ padding: "0.5rem", marginTop: "1rem" }}
                  onClick={handleValidateEmail}
                  disabled={emailStatus.status}
                >
                  {emailStatus.status
                    ? "Validating Email ..."
                    : "Validate Email"}
                </Button>
              </div>
            </div>
            <div className={classes["status_container"]}>
              <h2>Phone</h2>
              <div className={classes["status_table"]}>
                <div className={classes["table__row"]}>
                  <div className={classes["table__field"]}>Status</div>
                  <div
                    className={classes["table__value"]}
                    style={{
                      color: phoneStatus.status ? "green" : "red",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                    }}
                  >
                    {phoneStatus.status ? "Running" : "Stop"}
                  </div>
                </div>
                <div className={classes["table__row"]}>
                  <div className={classes["table__field"]}>
                    Validated Phones
                  </div>
                  <div className={classes["table__value"]}>
                    {phoneStatus.validated}
                  </div>
                </div>
                <div className={classes["table__row"]}>
                  <div className={classes["table__field"]}>
                    Unvalidated Phones
                  </div>
                  <div className={classes["table__value"]}>
                    {phoneStatus.unvalidate}
                  </div>
                </div>

                <Button
                  variant="contained"
                  sx={{ padding: "0.5rem", marginTop: "1rem" }}
                  onClick={handleValidatePhone}
                  disabled={phoneStatus.status}
                >
                  {phoneStatus.status
                    ? "Validating Phone ..."
                    : "Validate Phone"}
                </Button>
              </div>
            </div>
          </div>
        </Box>
      )}
    </>
  );
};

export default Validation;
